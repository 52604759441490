import request from '../request';
import { authHeader } from '../helper';

/**** 20200217 ****/
const GET_ALL_COURSE = '/api/customer-portal/courses';
const GET_COURSE = '/api/customer-portal/courses';

export async function getAllCourse(action) {
  try {
    const response = await request.get(GET_ALL_COURSE, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getCourse(action) {
  try {
    const response = await request.get(`${GET_COURSE}/${action.id}`, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
