import { requestConstantsMaker } from '../helper';

export const GET_ALL_COURSE_PUBLIC = requestConstantsMaker('GET_ALL_COURSE_PUBLIC');
export const GET_COURSE_PUBLIC = requestConstantsMaker('GET_COURSE_PUBLIC');

export const GET_ALL_COURSE_CATEGORY_PUBLIC = requestConstantsMaker('GET_ALL_COURSE_CATEGORY_PUBLIC');

export const GET_ALL_COURSE_CLASS_PUBLIC = requestConstantsMaker('GET_ALL_COURSE_CLASS_PUBLIC');
export const GET_COURSE_CLASS_PUBLIC = requestConstantsMaker('GET_COURSE_CLASS_PUBLIC');

export const CREATE_APPLICATION_PUBLIC = requestConstantsMaker('CREATE_APPLICATION_PUBLIC');

export const POST_SEND_INVOICE_BY_EMAIL = requestConstantsMaker('POST_SEND_INVOICE_BY_EMAIL');

export const CHECK_SESSION_CAPACITY = requestConstantsMaker('CHECK_SESSION_CAPACITY');
