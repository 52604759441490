import * as cons from './constants';
import { requestReducerMaker, initialState } from '../helper'

export const register = (state = initialState, action) => requestReducerMaker(cons.REGISTER, state, action);
export const getCustomer = (state = initialState, action) => requestReducerMaker(cons.GET_CUSTOMER, state, action);
export const updateCustomer = (state = initialState, action) => requestReducerMaker(cons.UPDATE_CUSTOMER, state, action);
export const changePassword = (state = initialState, action) => requestReducerMaker(cons.CHANGE_PASSWORD, state, action);
export const checkRegistrationProcess = (state = initialState, action) => requestReducerMaker(cons.CHECK_REGISTRATION_PROCESS, state, action);
export const sendResetPasswordEmail = (state = initialState, action) => requestReducerMaker(cons.SEND_RESET_PASSWORD_EMAIL, state, action);
export const resetPassword = (state = initialState, action) => requestReducerMaker(cons.RESET_PASSWORD, state, action);
