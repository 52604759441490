import request from '../request';
import { authHeader } from '../helper';

/**** 20200217 ****/
const GET_ALL_APPLICATION = '/api/customer-portal/applications';
const GET_APPLICATION = '/api/customer-portal/applications';
const CREATE_APPLICATION = '/api/customer-portal/applications';

export async function getAllApplication(action) {
  try {
    const response = await request.get(GET_ALL_APPLICATION, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getApplication(action) {
  try {
    const response = await request.get(`${GET_APPLICATION}/${action.id}`, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function createApplication(action) {
  try {
    const response = await request.post(`${CREATE_APPLICATION}`, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}