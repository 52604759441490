import React from 'react';
import { Router, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import App from './containers/App/App';
import PublicPage from './containers/PublicPage/App';
import asyncComponent from './helpers/AsyncFunc';

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn ? (
        <Component {...props} />
      ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
    }
  />
);
const PublicRoutes = ({ history, isLoggedIn }) => {
  return (
    <Router history={history}>
      <div>
        <Route
          exact
          path={'/'}
          component={asyncComponent(() => import('./containers/Page/login-page'))}
        />
        <Route
          exact
          path={'/signin'}
          component={asyncComponent(() => import('./containers/Page/login-page'))}
        />
        <Route
          exact
          path={'/register'}
          component={asyncComponent(() => import('./containers/Page/register-page'))}
        />
        <Route
          exact
          path={'/reset'}
          component={asyncComponent(() => import('./containers/Page/reset-password-page/email'))}
        />
        <Route
          exact
          path={'/reset/:customerId'}
          component={asyncComponent(() => import('./containers/Page/reset-password-page'))}
        />
        <Route
          path={'/public'}
          component={PublicPage}
        />
        <RestrictedRoute
          path="/app"
          component={App}
          isLoggedIn={isLoggedIn}
        />
      </div>
    </Router>
  );
};

export default connect(state => ({
  isLoggedIn: state.authInfo.accessToken !== undefined ? true : false,
}))(PublicRoutes);
