import React, { useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { checkRegistrationProcess } from '../../redux/request/actions';
import { notification } from 'antd';

export default function ({ ...rest }) {
  const dispatch = useDispatch();

  const result = useSelector(state => state.checkRegistrationProcess.data);
  const resultFetched = useSelector(state => state.checkRegistrationProcess.dataFetched);
  const resultLoading = useSelector(state => state.checkRegistrationProcess.isFetching);

  useEffect(() => {
    dispatch(checkRegistrationProcess());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (result && result.existing_applications > 0 && (!result.profile_agreed || result.unagree_students.length > 0)) {
    const args = {
      key: "important_notice",
      message: 'Important Notice',
      description:
        <div>
          Please fill out the following forms and read the terms and conditions carefully to complete the registration process
          <ul style={{ listStyleType: 'circle', marginLeft: 22 }}>
            {
              !result.profile_agreed &&
              <li><a href="/app/profile">Profile</a></li>
            }
            {
              result.unagree_students.map((student) => {
                return <li><a href={`/app/students/edit/${student.id}`}>{student.full_name}</a></li>
              })
            }
          </ul>
        </div>,
      duration: 0,
    };
    notification['error'](args);
  }

  return (
    <div></div>
  )
}
