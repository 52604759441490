import request from '../request';
import { authHeader } from '../helper';

const GET_ALL_STUDENT = '/api/customer-portal/students';
const GET_STUDENT = '/api/customer-portal/students';
const CREATE_STUDENT = '/api/customer-portal/students';
const UPDATE_STUDENT = '/api/customer-portal/students';

export async function getAllStudent(action) {
  try {
    const response = await request.get(GET_ALL_STUDENT, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getStudent(action) {
  try {
    const response = await request.get(`${GET_STUDENT}/${action.id}`, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function createStudent(action) {
  try {
    const response = await request.post(`${CREATE_STUDENT}`, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function updateStudent(action) {
  try {
    const response = await request.put(`${UPDATE_STUDENT}/${action.id}`,action.data, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getStudentsForSelection(action) {
  try {
    const response = await request.get(GET_ALL_STUDENT, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
