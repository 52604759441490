import request from '../request';
import { authHeader } from '../helper';

const REGISTER = '/api/customer-portal/customers/register';
const GET_CUSTOMER = '/api/customer-portal/customers/me';
const UPDATE_CUSTOMER = '/api/customer-portal/customers/me';
const CHANGE_PASSWORD = '/api/customer-portal/customers/change-password';
const CHECK_REGISTRATION_PROCESS = '/api/customer-portal/customers/check-registration-process';
const SEND_RESET_PASSWORD_EMAIL = '/api/customer-portal/customers/send-reset-password-email';
const RESET_PASSWORD = '/api/customer-portal/customers/reset-password';

export async function register(action) {
  try {
    const response = await request.post(`${REGISTER}`, action.data, {
      headers: {
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getCustomer(action) {
  try {
    const response = await request.get(`${GET_CUSTOMER}`, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function updateCustomer(action) {
  try {
    const response = await request.put(`${UPDATE_CUSTOMER}`,action.data, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function changePassword(action) {
  try {
    const response = await request.put(`${CHANGE_PASSWORD}`,action.data, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function checkRegistrationProcess(action) {
  try {
    const response = await request.get(`${CHECK_REGISTRATION_PROCESS}`, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function sendResetPasswordEmail(action) {
  try {
    const response = await request.put(`${SEND_RESET_PASSWORD_EMAIL}`, action.data, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function resetPassword(action) {
  try {
    const response = await request.put(`${RESET_PASSWORD}`,action.data, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
