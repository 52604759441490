import * as cons from './constants';
import { requestReducerMaker, initialState } from '../helper'

export const getAllCoursePublic = (state = initialState, action) => requestReducerMaker(cons.GET_ALL_COURSE_PUBLIC, state, action);
export const getCoursePublic = (state = initialState, action) => requestReducerMaker(cons.GET_COURSE_PUBLIC, state, action);

export const getAllCourseCategoryPublic = (state = initialState, action) => requestReducerMaker(cons.GET_ALL_COURSE_CATEGORY_PUBLIC, state, action);

export const getAllCourseClassPublic = (state = initialState, action) => requestReducerMaker(cons.GET_ALL_COURSE_CLASS_PUBLIC, state, action);
export const getCourseClassPublic = (state = initialState, action) => requestReducerMaker(cons.GET_COURSE_CLASS_PUBLIC, state, action);

export const createApplicationPublic = (state = initialState, action) => requestReducerMaker(cons.CREATE_APPLICATION_PUBLIC, state, action);

export const postSendInvoiceByEmail = (state = initialState, action) => requestReducerMaker(cons.POST_SEND_INVOICE_BY_EMAIL, state, action);

export const checkSessionCapacity = (state = initialState, action) => requestReducerMaker(cons.CHECK_SESSION_CAPACITY, state, action);
