import request from '../request';
import { authHeader } from '../helper';

const GET_ALL_INVOICE = '/api/customer-portal/invoices';
const GET_INVOICE = '/api/customer-portal/invoices';
const GET_INVOICE_BY_HASH_INVOICE_NUMBER = '/api/customer-portal/invoices/hash-invoice-number';

export async function getAllInvoice(action) {
  try {
    const response = await request.get(GET_ALL_INVOICE, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getInvoice(action) {
  try {
    const response = await request.get(`${GET_INVOICE}/${action.id}`, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getInvoiceByHashInvoiceNumber(action) {
  try {
    const response = await request.get(`${GET_INVOICE_BY_HASH_INVOICE_NUMBER}`, {
      headers: {
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

const POST_CREATE_INVOICE = '/api/v1/invoice';

export async function postCreateInvoice(action) {
    try {
      const response = await request.post(POST_CREATE_INVOICE, action.data, {
        headers: {
          ...authHeader(),
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
}
