import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';

const routes = [
  {
    path: '',
    component: asyncComponent(() => import('../Page/course-page')),
  },
  {
    path: 'courses',
    component: asyncComponent(() => import('../Page/course-page')),
  },
  {
    path: 'courses/:courseId',
    component: asyncComponent(() => import('../Page/course-page/view')),
  },
  {
    path: 'profile',
    component: asyncComponent(() => import('../Page/customer-page/edit')),
  },
  {
    path: 'students',
    component: asyncComponent(() => import('../Page/student-page')),
  },
  {
    path: 'students/create',
    component: asyncComponent(() => import('../Page/student-page/create')),
  },
  {
    path: 'students/edit/:studentId',
    component: asyncComponent(() => import('../Page/student-page/edit')),
  },
  {
    path: 'applications',
    component: asyncComponent(() => import('../Page/application-page')),
  },
  {
    path: 'applications/create',
    component: asyncComponent(() => import('../Page/application-page/create')),
  },
  {
    path: 'invoices',
    component: asyncComponent(() => import('../Page/invoice-page')),
  },
  {
    path: 'invoices/view/:invoiceId',
    component: asyncComponent(() => import('../Page/invoice-page/view')),
  },
  {
    path: 'receipts/view/:paymentDetailId',
    component: asyncComponent(() => import('../Page/receipt-page/view')),
  },
  {
    path: 'change-password',
    component: asyncComponent(() => import('../Page/change-password-page/edit')),
  },
];

class AppRouter extends Component {
  render() {
    const { url, style } = this.props;
    return (
      <>
          {routes.map(singleRoute => {
            const { path, exact, ...otherProps } = singleRoute;
            return (
              <Route
                exact={exact === false ? false : true}
                key={singleRoute.path}
                path={`${url}/${singleRoute.path}`}
                {...otherProps}
              />
            );
          })}
      </>
    );
  }
}

export default AppRouter;
