const options = [
  {
    key: 'courses',
    label: 'Course',
    leftIcon: 'ion-ios-timer',
  },
  {
    key: 'profile',
    label: 'Profile',
    leftIcon: 'ion-clipboard',
  },
  {
    key: 'students',
    label: 'Student',
    leftIcon: 'ion-ios-people',
  },
  {
    key: 'applications',
    label: 'Application',
    leftIcon: 'ion-social-buffer',
  },
  {
    key: 'invoices',
    label: 'Invoice',
    leftIcon: 'ion-ios-list-outline',
  },
  {
    key: 'change-password',
    label: 'Change Password',
    leftIcon: 'ion-android-lock',
  },
];
export default options;
