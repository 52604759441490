import * as applicationReducers from "./application/reducers"
import * as authReducers from "./auth/reducers"
import * as courseReducers from "./course/reducers"
import * as courseCategoryReducers from "./course_category/reducers"
import * as courseClassReducers from "./course_class/reducers"
import * as customerReducers from "./customer/reducers"
import * as invoiceReducers from "./invoice/reducers"
import * as paymentReducers from "./payment/reducers"
import * as studentReducers from "./student/reducers"
import * as publicRegistrationReducers from "./public-registration/reducers"

export default {
    ...applicationReducers,
    ...authReducers,
    ...courseReducers,
    ...courseCategoryReducers,
    ...courseClassReducers,
    ...customerReducers,
    ...invoiceReducers,
    ...paymentReducers,
    ...studentReducers,
    ...publicRegistrationReducers,
};
