import request from '../request';
import { authHeader } from '../helper';

const GET_ALL_COURSE_CATEGORY = '/api/customer-portal/course-categories';

export async function getAllCourseCategory(action) {
  try {
    const response = await request.get(GET_ALL_COURSE_CATEGORY, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}