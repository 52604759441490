import * as cons from './constants';
import * as apis from './apis';
import { requestActionMaker } from '../helper';

export const getAllCoursePublic = para => requestActionMaker({ cons: cons.GET_ALL_COURSE_PUBLIC, api: apis.getAllCoursePublic, ...para });
export const getCoursePublic = para => requestActionMaker({ cons: cons.GET_COURSE_PUBLIC, api: apis.getCoursePublic, ...para });

export const getAllCourseCategoryPublic = para => requestActionMaker({ cons: cons.GET_ALL_COURSE_CATEGORY_PUBLIC, api: apis.getAllCourseCategoryPublic, ...para });

export const getAllCourseClassPublic = para => requestActionMaker({ cons: cons.GET_ALL_COURSE_CLASS_PUBLIC, api: apis.getAllCourseClassPublic, ...para });
export const getCourseClassPublic = para => requestActionMaker({ cons: cons.GET_COURSE_CLASS_PUBLIC, api: apis.getCourseClassPublic, ...para });

export const createApplicationPublic = para => requestActionMaker({ cons: cons.CREATE_APPLICATION_PUBLIC, api: apis.createApplicationPublic, ...para });

export const postSendInvoiceByEmail = para => requestActionMaker({ cons: cons.POST_SEND_INVOICE_BY_EMAIL, api: apis.postSendInvoiceByEmail, ...para });

export const checkSessionCapacity = para => requestActionMaker({ cons: cons.CHECK_SESSION_CAPACITY, api: apis.checkSessionCapacity, ...para });
