import request from '../request';
import { authHeader } from '../helper';

const GET_PAYMENT_DETAIL = '/api/customer-portal/payments/details';
const PAY_INVOICE_BY_PALPAY = '/api/customer-portal/payments/pay-invoice-by-paypal';

export async function getPaymentDetail(action) {
  try {
    const response = await request.get(`${GET_PAYMENT_DETAIL}/${action.id}`, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function payInvoiceByPaypal(action) {
  try {
    const response = await request.post(`${PAY_INVOICE_BY_PALPAY}`, action.data, {
      headers: {
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}