import request from '../request';
import { authHeader } from '../helper';

/**** 20200217 ****/
const GET_ALL_COURSE_PUBLIC = '/api/customer-portal/public/courses';
const GET_COURSE_PUBLIC = '/api/customer-portal/public/courses';

export async function getAllCoursePublic(action) {
  try {
    const response = await request.get(GET_ALL_COURSE_PUBLIC, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getCoursePublic(action) {
  try {
    const response = await request.get(`${GET_COURSE_PUBLIC}/${action.id}`, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

const GET_ALL_COURSE_CATEGORY_PUBLIC = '/api/customer-portal/public/course-categories';

export async function getAllCourseCategoryPublic(action) {
  try {
    const response = await request.get(GET_ALL_COURSE_CATEGORY_PUBLIC, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

const GET_ALL_COURSE_CLASS_PUBLIC = '/api/customer-portal/public/course-classes';
const GET_COURSE_CLASS_PUBLIC = '/api/customer-portal/public/course-classes';

export async function getAllCourseClassPublic(action) {
  try {
    const response = await request.get(GET_ALL_COURSE_CLASS_PUBLIC, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getCourseClassPublic(action) {
  try {
    console.log(action);
    const response = await request.get(`${GET_COURSE_CLASS_PUBLIC}/${action.id}`, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

const CREATE_APPLICATION_PUBLIC = '/api/customer-portal/public/applications';

export async function createApplicationPublic(action) {
  try {
    const response = await request.post(`${CREATE_APPLICATION_PUBLIC}`, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

const POST_SEND_INVOICE_BY_EMAIL = '/api/v1/invoice/sendInvoiceViaEmailPublic';

export async function postSendInvoiceByEmail(action) {
  try {
    const response = await request.post(`${POST_SEND_INVOICE_BY_EMAIL}/${action.id}`, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

const CHECK_SESSION_CAPACITY = '/api/customer-portal/public/check-capacity'

export async function checkSessionCapacity(action) {
  try {
    const response = await request.post(`${CHECK_SESSION_CAPACITY}`, action.data, {
      headers: {
        ...authHeader(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
