import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import TopbarWrapper from "./topbar.style";
import themes from "../../../settings/themes";
import { themeConfig } from "../../../settings";
import StepsLogo from "../../../images/steps_logo.png";
import StepKhLogo from "../../../images/steps_kh_logo.png";
import StepsPlusLogo from "../../../images/steps_plus_logo.png";
import { siteConfig } from '../../../settings';

const { Header } = Layout;
const customizedTheme = themes[themeConfig.theme];

class Topbar extends Component {
  render() {
    const collapsed = this.props.collapsed && !this.props.openDrawer;
    const styling = {
      background: customizedTheme.backgroundColor,
      position: "fixed",
      width: "100%",
      height: 70
    };
    return (
      <TopbarWrapper>
        <Header
          style={styling}
          className={
            collapsed ? "isomorphicTopbar collapsed" : "isomorphicTopbar"
          }
        >
          <div className="isoLeft">
            <img src={siteConfig.siteLogo === 'KH' ? StepKhLogo : siteConfig.siteLogo === 'CB' ? StepsPlusLogo : StepsLogo} alt='website logo' style={{ maxWidth: 80 }} />
            <span style={{ marginLeft: '20px' }}>Online Registration Portal</span>
          </div>
        </Header>
      </TopbarWrapper>
    );
  }
}

export default connect(
  state => ({
    ...state.App
  }),
  {}
)(Topbar);
