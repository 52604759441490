import request from '../request';
import { authHeader } from '../helper';

/**** 20200217 ****/
const GET_ALL_COURSE_CLASS = '/api/customer-portal/course-classes';
const GET_COURSE_CLASS = '/api/customer-portal/course-classes';

export async function getAllCourseClass(action) {
  try {
    const response = await request.get(GET_ALL_COURSE_CLASS, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getCourseClass(action) {
  try {
    console.log(action);
    const response = await request.get(`${GET_COURSE_CLASS}/${action.id}`, {
      headers: {
        ...authHeader(),
      },
      params: action.params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
