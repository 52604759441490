import * as cons from './constants';
import * as apis from './apis';
import { requestActionMaker } from '../helper';

export const register = para => requestActionMaker({ cons: cons.REGISTER, api: apis.register, ...para });
export const getCustomer = para => requestActionMaker({ cons: cons.GET_CUSTOMER, api: apis.getCustomer, ...para });
export const updateCustomer = para => requestActionMaker({ cons: cons.UPDATE_CUSTOMER, api: apis.updateCustomer, ...para });
export const changePassword = para => requestActionMaker({ cons: cons.CHANGE_PASSWORD, api: apis.changePassword, ...para });
export const checkRegistrationProcess = para => requestActionMaker({ cons: cons.CHECK_REGISTRATION_PROCESS, api: apis.checkRegistrationProcess, ...para });
export const sendResetPasswordEmail = para => requestActionMaker({ cons: cons.SEND_RESET_PASSWORD_EMAIL, api: apis.sendResetPasswordEmail, ...para });
export const resetPassword = para => requestActionMaker({ cons: cons.RESET_PASSWORD, api: apis.resetPassword, ...para});
